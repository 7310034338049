import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from '../components/Homepage.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Unterkünfte in Berlin - geprüft von einem Rollstuhlfahrer für Rollstuhlfahrer`}</h2>
    <p>
Als Programmierer und IT-Consultant reise ich viel durch Deutschland. Als Rollstuhlfahrer ist hier die
Barrierefreiheit der Unterkünfte natürlich absolut notwendig für mich. Leider erlebe ich es allzu oft, dass ich
zwar bei der Buchung immer wieder betone, dass ich ein rollstuhlgerechtes Zimmer brauche, die Gegebenheiten vor Ort
allerdings absolut nicht meinen Bedürfnissen entsprechen.
Daher habe ich mich entschieden, auf dieser Webseite Berliner Hotels zu präsentieren, die nach <strong>MEINEM</strong> persönlichen
Empfinden barrierefrei sind.
    </p>
    <p>
    Es handelt sich hierbei weder um irgendeine offizielle Prüfung noch kann ich versprechen, dass die Angaben auf
    dieser Webseite stets den Gegebenheiten entsprechen, da sich diese auch im Laufe der Zeit verändern können.
    Die angegebenen Informationen spiegeln lediglich das wider, was ich vor Ort gesehen habe.
    Aus diesem Grund bin ich auch nicht für die Angaben haftbar. Es handelt sich hier lediglich um einen Erfahrungsbericht
    bzw. Empfehlungen.
    </p>
    <p>
    Falls ich für Sie eine Unterkunft in Berlin besuchen soll oder Sie der Inhaber einer Unterkunft sind und sie von mir
    überprüfen lassen wollen, kontaktieren Sie mich sehr gerne! Beides ist selbstverständlich kostenlos.
    Senden Sie hierzu gern eine E-mail an: <a href="mailto: florianwuensche@yahoo.de">florianwuensche@yahoo.de</a>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      